@import "styles/core.scss";
.mobileButtonsContainer {
  @include flex-row;
  gap: var(--space-100);
  justify-content: space-between;
}

.rightButtonsContainer {
  @include flex-row;
  gap: var(--space-100);
}

.button {
  position: relative;
  background-color: var(--color-background-primary);
}

.iconContainer {
  position: relative;

  .plusBadge {
    position: absolute;
    top: 0;
    right: 0;
  }
}
