@import "styles/core.scss";
.nav {
  margin: var(--space-600) 0px var(--space-300);
}

.expandableContainer {
  @include flex-column;
  margin-bottom: var(--space-600);
}

.footerLinksContainer {
  display: none;
}

@include medium-up {
  .nav {
    margin-bottom: var(--space-600);
  }

  .expandableContainer {
    display: none;
  }

  .footerLinksContainer {
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
