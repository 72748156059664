@import "styles/core.scss";
.botContainer {
  @include flex-column;
}

.inlineAlerts {
  @include text-100;
  display: flex;
  gap: var(--space-50);
}

.highPriorityAlerts {
  gap: var(--space-100);
}

.highPriorityAlerts,
.otherAlerts {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.highPriorityAlerts {
  color: var(--color-text-error);
}

.otherAlerts {
  color: var(--color-text-caution);
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.inlineAlertsLink.inlineAlertsLink {
  color: var(--color-text-error);
}

.inlineCautionAlertsLink.inlineCautionAlertsLink {
  color: var(--color-text-caution);
  margin-left: var(--space-100);
  font-size: var(--font-size-100);
}

.dividerDot {
  @include text-100;
  vertical-align: middle;
  color: var(--color-text-secondary);
  margin: 0 var(--space-50);
}
