@import "styles/core.scss";
.desktopButtonsContainer {
  @include flex-row;
  gap: var(--space-100);
  justify-content: flex-end;
}

.iconContainer {
  position: relative;

  .plusBadge {
    position: absolute;
    top: 0;
    right: 0;
  }
}
