@import "styles/core.scss";
.container {
  width: 100%;
}

.appIcon {
  margin-right: var(--space-150);
}

.title {
  @include text-300-bold;
  margin-bottom: var(--space-100);
  white-space: break-spaces;
  color: var(--color-text-primary-inverse);
}

.appIconsContainer {
  white-space: nowrap;
}

@include medium-up {
  .title {
    margin-bottom: var(--space-300);
  }
}
