@import "styles/core.scss";
.photoHeaderGridContainer {
  @include flex-row;
  aspect-ratio: 2.09;
  border-radius: var(--radius-lg);
  gap: var(--space-50);
  overflow: hidden;
  position: relative;

  @include page-width-sm-down {
    display: none;
  }
}

button.imageContainer {
  @include removeDefaultButtonStyles;
  cursor: pointer;
  position: relative;

  &.gradient::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 50%);
  }
}

.primaryImage {
  aspect-ratio: 4/3;
  flex: 3;
}

.verticalImages {
  @include flex-column;
  gap: var(--space-50);
  flex: 2;
}

.secondaryImage {
  flex: 1;
}

.locationPhoto {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all var(--duration-short) ease-in-out;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.showMore.showMore {
  position: absolute;
  bottom: var(--space-200);
  left: var(--space-200);
}
